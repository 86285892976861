import React from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import CBInputField from '../../shared/form_components/CBInputField';
import CBSubmitButton from '../../shared/form_components/CBSubmitButton';
import CBButtonSettings from '../../shared/CBButtonSettings';
import CBSpinnerFloating from '../../shared/CBSpinnerFloating';
import {
  getLengthValidator,
  getNumberValidator,
  getPhoneValidator,
  getEmailValidator,
} from '../../../utils/validators';
import CBDatePicker from '../../shared/CBDatePicker';
import { API_URL } from '../../../config';
import CBLabelAndSwitch from '../../shared/CBLabelAndSwitch';

class WorkshopForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formData: Object.assign({}, props.workshop), formValid: {}, token: '' };
  }

  componentWillReceiveProps(nextProps) {
    const { workshop } = this.props;

    if (workshop.id !== nextProps.workshop.id) {
      this.setState({ formData: Object.assign({}, nextProps.workshop), formValid: {}, token: '' });
    }
  }

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
    };
    newFormValid[name] = isValid;

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  toggleFormStateBoolean = property => {
    this.setState(prevState => {
      const { formData } = prevState;
      const newFormData = Object.assign({}, formData);
      newFormData[property] = !newFormData[property];
      return { formData: newFormData };
    });
  };

  submitForm = () => {
    const { formData } = this.state;
    const { createWorkshop, updateWorkshop, workshop } = this.props;
    const dateObject = {
      'subscription_begin': formData.subscription_begin ? moment(formData.subscription_begin).format('YYYY-MM-DD') : null,
      'subscription_end': formData.subscription_end ? moment(formData.subscription_end).format('YYYY-MM-DD') : null,
      'hide_maintenances_before': formData.hide_maintenances_before ? moment(formData.hide_maintenances_before).format('YYYY-MM-DD') : null,
    };
    const newFormData = Object.assign({}, formData, dateObject);
    if (workshop.id) {
      updateWorkshop(workshop.id, newFormData);
    } else {
      createWorkshop(newFormData);
    }
  };

  changeStartDate = (date) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['subscription_begin'] = date;
    this.setState({ formData: newFormData });
  }

  changeEndDate = (date) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['subscription_end'] = date;
    this.setState({ formData: newFormData });
  }

  changeHideMaintenancesDate = (date) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['hide_maintenances_before'] = date;
    this.setState({ formData: newFormData });
  }

  fetchApiToken = () => {
    const { t, workshop } = this.props;
    axios({
      url: `${API_URL}/uiapi/1/workshop_token/?workshop_id=${workshop.id}`,
      method: 'GET',
    })
      .then(response => response.data)
      .then(data => {
        this.setState({ token: data.token });
      })
      .catch(() => {
        this.setState({ token: t('tokenErrorText') });
      });
  };

  render() {
    const { t, workshopRequest } = this.props;
    const { formData, token } = this.state;
    const beginDate = formData.subscription_begin ? moment(formData.subscription_begin) : null;
    const endDate = formData.subscription_end ? moment(formData.subscription_end) : null;
    const hideMaintenancesDate = formData.hide_maintenances_before ? moment(formData.hide_maintenances_before) : null;
    return (
      <div style={styles.formContainer}>
        {workshopRequest.workshopStatus.loading && <CBSpinnerFloating />}
        <div style={styles.tokenContainer}>
          <p style={styles.tokenLabel}>{t('databaseId')}</p>
          <p style={styles.tokenText}>{formData ? formData.id : ''}</p>
        </div>
        <div style={styles.tokenContainer}>
          <p style={styles.tokenLabel}>{t('traficomCustomerCode')}</p>
          <p style={styles.tokenText}>{formData ? formData.customer_code : ''}</p>
        </div>
        <CBInputField
          labelText={t('businessIdLabel')}
          name='business_id'
          onChange={this.updateFormState}
          value={formData.business_id}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(9, 9)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('workshopNameLabel')}
          name='name'
          onChange={this.updateFormState}
          value={formData.name}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(2, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('organizationNameLabel')}
          name='organization_name'
          onChange={this.updateFormState}
          value={formData.organization_name}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(2, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('addressLabel')}
          name='street_address'
          onChange={this.updateFormState}
          value={formData.street_address}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(2, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('zipCodeLabel')}
          name='zip_code'
          onChange={this.updateFormState}
          type='number'
          value={formData.zip_code}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getNumberValidator(), getLengthValidator(5, 10)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('cityLabel')}
          name='city'
          onChange={this.updateFormState}
          value={formData.city}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(2, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('phoneNumberLabel')}
          name='phone_number'
          onChange={this.updateFormState}
          value={formData.phone_number}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getPhoneValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('contactNameLabel')}
          name='contact_name'
          onChange={this.updateFormState}
          value={formData.contact_name}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(2, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('contactPhoneNumberLabel')}
          name='contact_phone_number'
          onChange={this.updateFormState}
          value={formData.contact_phone_number}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getPhoneValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('email')}
          name='email'
          onChange={this.updateFormState}
          value={formData.email}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getEmailValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('url')}
          name='url'
          onChange={this.updateFormState}
          value={formData.url}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(2, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('discountOwnVehicles')}
          name='own_vehicle_discount_percent'
          onChange={this.updateFormState}
          type='number'
          value={formData.own_vehicle_discount_percent}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getNumberValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('discountCareServiceVehicles')}
          name='care_service_vehicle_discount_percent'
          onChange={this.updateFormState}
          type='number'
          value={formData.care_service_vehicle_discount_percent}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getNumberValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />

        <p className='font-titillium-web-semi-bold' style={styles.fieldContainerLeftAlign}>
          {t('contractPeriodLabel')}
        </p>

        <div style={styles.datepickerContainer}>
          <CBDatePicker
            date={beginDate}
            changeDate={this.changeStartDate}
            containerStyle={styles.datePickerInnerContainer}
          />
          <img
            src='/img/icon_arrow_calendar.svg'
            alt='close'
            style={styles.arrowIcon}
          />
          <CBDatePicker
            date={endDate}
            changeDate={this.changeEndDate}
            containerStyle={styles.datePickerInnerContainer}
          />
        </div>

        <p className='font-titillium-web-semi-bold' style={styles.fieldContainerLeftAlign}>
          {t('hideMaintenancesBeforeLabel')}
        </p>

        <div style={styles.datepickerContainer}>
          <CBDatePicker
            date={hideMaintenancesDate}
            changeDate={this.changeHideMaintenancesDate}
            containerStyle={styles.datePickerInnerContainer}
          />
          <div style={styles.clearButtonContainer}>
            <CBButtonSettings text={t('clear')} onClick={() => { this.changeHideMaintenancesDate(null); }} />
          </div>
        </div>

        <CBInputField
          labelText={t('autofuturIdLabel')}
          name='autofutur_id'
          onChange={this.updateFormState}
          value={formData.autofutur_id}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />

        <CBInputField
          labelText={t('automasterEnterpriseIdLabel')}
          name='automaster_enterprise_id'
          onChange={this.updateFormState}
          value={formData.automaster_enterprise_id}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('automasterStoreIdLabel')}
          name='automaster_store_id'
          onChange={this.updateFormState}
          value={formData.automaster_store_id}
          serverErrors={workshopRequest.workshopStatus.error}
          isValidCallback={this.setIsValid}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />

        <div style={styles.tokenContainer}>
          <p style={styles.tokenLabel}>{t('tokenHeading')}</p>
          {!token && (
            <CBButtonSettings onClick={this.fetchApiToken} text={t('tokenShowButton')} />
          )}
          <p style={styles.tokenText}>
            {token}
          </p>
        </div>

        <CBLabelAndSwitch
          labelText={t('testWorkshop')}
          onChange={() => this.toggleFormStateBoolean('test_workshop')}
          checked={formData.test_workshop}
          size='medium'
        />

        <CBLabelAndSwitch
          labelText={t('showMaintenanceDescriptions')}
          onChange={() => this.toggleFormStateBoolean('show_maintenance_descriptions')}
          checked={formData.show_maintenance_descriptions}
          size='medium'
        />

        <CBSubmitButton
          disabled={workshopRequest.workshopStatus.loading || !this.getIsValid()}
          onClick={this.submitForm}
        />
      </div>
    );
  }
}

const styles = {
  clearButtonContainer: {
    marginLeft: 8,
    display: 'inline-block',
  },
  formContainer: {
    position: 'relative',
  },
  arrowIcon: {
    margin: '0px 8px',
    width: 32,
    height: 32,
    fill: '#ffffff',
  },
  datePickerInnerContainer: {
    // marginRight: 16,
    width: 150,
  },
  datepickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 24,
  },
  fieldContainerLeftAlign: {
    marginBottom: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  tokenContainer: {
    marginBottom: 30,
  },
  tokenLabel: {
    fontFamily: 'TitilliumWeb-SemiBold',
  },
};

export default translate('SettingsView')(WorkshopForm);
